@import "~bootstrap/scss/functions";
@import "~navex-styles/base/functions";
@import "~navex-styles/base/mixins";
@import "~navex-styles/base/variables-base";
@import "~navex-styles/base/variables-colors";
@import "~navex-styles/base/variables-bootstrap";
@import "~navex-styles/base/helpers";
@import "~navex-styles/base/colors";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/variables";
@import "~navex-styles/base/core";
@import "~navex-styles/modules/progress-indicators";

*,
*::before,
*::after {
	box-sizing: border-box; // 1
}

html, body {
	padding: 0;
	margin: 0;
	height: 100%;
	overflow: hidden;
}

body {
	min-width: 0; /* Override the value specified by navex style guide*/
}

iframe {
	border: 0;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
